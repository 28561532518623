module.exports = [{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":710},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-60177543-1"},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"#c62828"},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Developerki/newap-gatsby-site/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    }]
